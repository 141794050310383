import React from "react"
import { Link, graphql, useStaticQuery } from "gatsby"
import Container from "../container/Container"
import SvgAnchorFm from "../../commons/svgs/SvgAnchorFm"
import SvgSpotify from "../../commons/svgs/SvgSpotify"
import SvgItunes from "../../commons/svgs/SvgItunes"
import SvgGooglePodcast from "../../commons/svgs/SvgGooglePodcast"
import SvgInstagram from "../../commons/svgs/SvgInstagram"

const Footer = () => {
  const data = useStaticQuery(graphql`
    query {
      allCockpitRechtliches(sort: { order: DESC, fields: title___value }) {
        nodes {
          slug {
            value
          }
          title {
            value
          }
        }
      }
    }
  `)

  const menuItems = [
    {
      text: "Startseite",
      to: "/",
    },
    {
      text: "Alle Folgen",
      to: "/folgen/",
    },
    {
      text: "Über uns",
      to: "/ueber-uns/",
    },
    {
      text: "Gäste",
      to: "/gaeste/",
    },
    {
      text: "Kontakt",
      to: "/kontakt/",
    },
  ]

  const socialIcons = [
    {
      title: "AnchorFM",
      to: "https://anchor.fm/summeallerdinge",
      svg: <SvgAnchorFm className="inline w-8 h-8" />,
    },
    {
      title: "Spotify",
      to: "https://open.spotify.com/show/4YkdLaWDtYNqx8auJLOlzq",
      svg: <SvgSpotify className="inline w-8 h-8" />,
    },
    {
      title: "Apple Podcast",
      to:
        "https://podcasts.apple.com/de/podcast/die-summe-aller-dinge/id1546648170",
      svg: <SvgItunes className="inline w-8 h-8" />,
    },
    {
      title: "Google Podcast",
      to:
        "https://podcasts.google.com/feed/aHR0cHM6Ly9hbmNob3IuZm0vcy80MzQ1MDA5MC9wb2RjYXN0L3Jzcw?sa=X&ved=0CAYQrrcFahcKEwjQv__o56TuAhUAAAAAHQAAAAAQAg",
      svg: <SvgGooglePodcast className="inline w-8 h-8" />,
    },
    {
      title: "Instagram",
      to: "https://www.instagram.com/summeallerdinge/",
      svg: <SvgInstagram className="inline w-8 h-8" />,
    },
  ]

  return (
    <footer className="pb-6 bg-white">
      <Container>
        <nav className="flex flex-wrap justify-center" aria-label="Footer">
          {menuItems.map((item, i) => {
            return (
              <div className="px-5 py-2" key={i}>
                <Link
                  to={item.to}
                  className="text-base text-gray-500 hover:text-gray-900 hover:underline"
                >
                  {item.text}
                </Link>
              </div>
            )
          })}
          {data.allCockpitRechtliches.nodes.map((item, i) => {
            return (
              <div className="px-5 py-2" key={i}>
                <Link
                  to={`/${item.slug.value}/`}
                  className="text-base text-gray-500 hover:text-gray-900 hover:underline"
                >
                  {item.title.value}
                </Link>
              </div>
            )
          })}
          {typeof window !== `undefined` &&
          process.env.NODE_ENV !== "development" ? (
            <div className="px-5 py-2">
              <button
                class="text-base text-gray-500 hover:text-gray-900  hover:underline"
                onclick={() => {
                  window.klaro.show()
                }}
              >
                Cookie Einstellungen
              </button>
            </div>
          ) : (
            ""
          )}
        </nav>
        <div className="flex justify-center mt-8 space-x-6">
          {socialIcons.map((item, i) => {
            return (
              <a
                href={item.to}
                className="text-gray-400 hover:text-gray-500"
                target="_blank"
                rel="noopener noreferrer"
              >
                <span className="sr-only">{item.title}</span>
                {item.svg}
              </a>
            )
          })}
        </div>
        <p className="mt-8 text-base text-center text-gray-400">
          © 2020 Die Summe aller Dinge, Inc. Alle Rechte vorbehalten.
        </p>
      </Container>
    </footer>
  )
}

export default Footer
