import React from "react"

const SvgItunes = ({ className, fill }) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 20 20"
      fill={fill ? fill : "currentColor"}
      className={className}
    >
      <path d="M10 0C4.5 0 0 4.5 0 10s4.5 10 10 10 10-4.5 10-10S15.5 0 10 0zm0 .9c5 0 9.1 4.1 9.1 9.1S15 19.1 10 19.1.9 15 .9 10 5 .9 10 .9zm3.9 3.4l-6 1.2c-.3 0-.5.3-.5.6v5.8c0 .2-.1.4-.3.4l-1.5.4h-.1c-.7.2-1.2.9-1.1 1.7.1.8.8 1.3 1.6 1.3 1.2 0 2.2-1 2.2-2.2V8.8c0-.3.2-.5.5-.6l4.3-.8c.2 0 .5.1.5.4V10.6c0 .2-.1.4-.3.4l-1.5.4h-.1c-.7.2-1.2.9-1.1 1.7.1.8.8 1.3 1.6 1.3 1.2 0 2.2-1 2.2-2.2V4.8c0-.2-.1-.4-.4-.5z" />{" "}
    </svg>
  )
}

export default SvgItunes
