import React from "react"

const Container = ({ children, className }) => {
  return (
    <div className={`mx-auto max-w-4xl px-4 ${className ? className : ""}`}>
      {children}
    </div>
  )
}

export default Container
