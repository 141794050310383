import React, { useState } from "react"
import { Link } from "gatsby"
import Logo from "../../commons/svgs/SvgLogo"
import Transition from "./../../transistion/Transition"
import SvgHome from "../../commons/svgs/SvgHome"
import SvgMicrophone from "../../commons/svgs/SvgMicrophone"
import SvgUsers from "../../commons/svgs/SvgUsers"
import SvgMail from "../../commons/svgs/SvgMail"

const Header = () => {
  const [mobileMenuOpen, setMobileMenuOpen] = useState(false)

  const menuItems = [
    {
      text: "Startseite",
      to: "/",
      svg: <SvgHome className="inline w-6 h-6" />,
    },
    {
      text: "Alle Folgen",
      to: "/folgen/",
      svg: <SvgMicrophone className="inline w-6 h-6" />,
    },
    {
      text: "Über uns",
      to: "/ueber-uns/",
      svg: <SvgUsers className="inline w-6 h-6" />,
    },
    {
      text: "Gäste",
      to: "/gaeste/",
      svg: <SvgUsers className="inline w-6 h-6" />,
    },
    {
      text: "Kontakt",
      to: "/kontakt/",
      svg: <SvgMail className="inline w-6 h-6" />,
    },
  ]

  return (
    <div className="relative bg-black">
      <div className="flex items-center justify-between px-4 py-6 mx-auto sm:px-6 mmd:justify-start mmd:space-x-10 max-w-7xl">
        <Link to="/">
          <Logo className="h-16" />
        </Link>
        <div className="-my-2 -mr-2 mmd:hidden">
          <button
            type="button"
            className="inline-flex items-center justify-center p-2 text-white bg-transparent rounded-md hover:bg-black900 focus:outline-none focus:ring-2 focus:ring-inset focus:ring-gray-500"
            onClick={() => setMobileMenuOpen(!mobileMenuOpen)}
          >
            <span className="sr-only">Open menu</span>
            {/* Heroicon name: menu */}
            <svg
              className="w-6 h-6"
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 24 24"
              stroke="currentColor"
              aria-hidden="true"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth={2}
                d="M4 6h16M4 12h16M4 18h16"
              />
            </svg>
          </button>
        </div>
        <div className="hidden mmd:flex-1 mmd:flex mmd:items-end mmd:justify-end">
          <nav className="flex items-center space-x-10">
            {menuItems.map((item, i) => {
              return (
                <Link
                  to={item.to}
                  className="text-base font-medium text-white hover:text-gray-500"
                  key={i}
                >
                  {item.text}
                </Link>
              )
            })}
            {/* <a
              href="#"
              className="inline-flex items-center justify-center px-4 py-2 ml-8 text-base font-medium text-black bg-indigo-600 border border-transparent rounded-md shadow-sm hover:bg-indigo-700"
            >
              Sign up
            </a> */}
          </nav>
        </div>
      </div>

      <Transition
        show={mobileMenuOpen}
        enter="duration-200 ease-out"
        enterFrom="opacity-0 scale-95"
        enterTo="opacity-100 scale-100"
        leave="duration-100 ease-in"
        leaveFrom="opacity-100 scale-100"
        leaveTo="opacity-0 scale-95"
      >
        <div className="absolute inset-x-0 top-0 z-40 p-2 transition origin-top-right transform mmd:hidden">
          <div className="divide-y-2 divide-gray-800 rounded-lg shadow-lg ring-1 ring-black ring-opacity-5 bg-black900">
            <div className="px-5 pt-5 pb-6">
              <div className="flex items-center justify-between">
                <div>
                  <Logo className="h-12" />
                </div>
                <div className="-mr-2">
                  <button
                    type="button"
                    className="inline-flex items-center justify-center p-2 text-white bg-transparent rounded-md hover:text-white hover:bg-black800 focus:outline-none focus:ring-2 focus:ring-inset focus:ring-gray-500"
                    onClick={() => setMobileMenuOpen(!mobileMenuOpen)}
                  >
                    <span className="sr-only">Menü schließen</span>
                    {/* Heroicon name: x */}
                    <svg
                      className="w-6 h-6"
                      xmlns="http://www.w3.org/2000/svg"
                      fill="none"
                      viewBox="0 0 24 24"
                      stroke="currentColor"
                      aria-hidden="true"
                    >
                      <path
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        strokeWidth={2}
                        d="M6 18L18 6M6 6l12 12"
                      />
                    </svg>
                  </button>
                </div>
              </div>
              <div className="mt-6">
                <nav className="grid gap-6">
                  {menuItems.map((item, i) => {
                    return (
                      <Link
                        to={item.to}
                        className="flex items-center p-3 -m-3 rounded-lg hover:bg-black800"
                        key={i}
                      >
                        <div className="flex items-center justify-center flex-shrink-0 w-10 h-10 text-white rounded-md">
                          {/* Heroicon name: chart-bar */}
                          {item.svg}
                        </div>
                        <div className="ml-4 text-base font-medium text-white">
                          {item.text}
                        </div>
                      </Link>
                    )
                  })}
                </nav>
              </div>
            </div>
            {/* <div className="px-5 py-6">
              <div className="grid grid-cols-2 gap-4">
                <a
                  href="#"
                  className="text-base font-medium text-gray-900 hover:text-gray-700"
                >
                  Pricing
                </a>
                <a
                  href="#"
                  className="text-base font-medium text-gray-900 hover:text-gray-700"
                >
                  Docs
                </a>
                <a
                  href="#"
                  className="text-base font-medium text-gray-900 hover:text-gray-700"
                >
                  Enterprise
                </a>
                <a
                  href="#"
                  className="text-base font-medium text-gray-900 hover:text-gray-700"
                >
                  Blog
                </a>
                <a
                  href="#"
                  className="text-base font-medium text-gray-900 hover:text-gray-700"
                >
                  Help Center
                </a>
                <a
                  href="#"
                  className="text-base font-medium text-gray-900 hover:text-gray-700"
                >
                  Guides
                </a>
                <a
                  href="#"
                  className="text-base font-medium text-gray-900 hover:text-gray-700"
                >
                  Security
                </a>
                <a
                  href="#"
                  className="text-base font-medium text-gray-900 hover:text-gray-700"
                >
                  Events
                </a>
              </div>
              <div className="mt-6">
                <a
                  href="#"
                  className="flex items-center justify-center w-full px-4 py-2 text-base font-medium text-black bg-indigo-600 border border-transparent rounded-md shadow-sm hover:bg-indigo-700"
                >
                  Sign up
                </a>
                <p className="mt-6 text-base font-medium text-center text-gray-500">
                  Existing customer?
                  <a href="#" className="text-indigo-600 hover:text-indigo-500">
                    Sign in
                  </a>
                </p>
              </div>
            </div> */}
          </div>
        </div>
      </Transition>
    </div>
  )
}

export default Header
