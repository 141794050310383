import React from "react"

const SvgAnchorFm = ({ className, fill }) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 24 24"
      fill={fill ? fill : "currentColor"}
      className={className}
    >
      <path d="M9.6 10.8H5.8c-.4-.7-1.2-1.2-2-1.2-1.3 0-2.4 1.1-2.4 2.4s1.1 2.4 2.4 2.4c.8 0 1.6-.5 2-1.2h3.9c.7 0 1.2-.5 1.2-1.2-.1-.6-.6-1.2-1.3-1.2z" />
      <path d="M12.6 17.1c2.8-1.6 3.8-5.2 2.2-8-.5-.9-1.3-1.6-2.2-2.2-.6-.3-1.3-.1-1.6.5-.3.5-.1 1.2.4 1.5 1.7 1 2.3 3.1 1.3 4.8-.3.5-.8 1-1.3 1.3-.6.3-.8 1-.5 1.6.2.4.6.6 1 .6.3.1.5 0 .7-.1z" />
      <path d="M13.7 20.5c4.7-2.2 6.7-7.8 4.5-12.6-.9-2-2.5-3.6-4.5-4.5-.6-.3-1.3 0-1.6.6-.3.6 0 1.3.6 1.6 3.5 1.7 5 5.9 3.4 9.4-.7 1.5-1.9 2.7-3.4 3.4-.6.3-.9 1-.6 1.6.2.4.6.7 1.1.7.2-.1.3-.1.5-.2z" />
      <path d="M14.8.1c-.6-.3-1.3-.1-1.6.5-.3.6-.1 1.3.5 1.6h.1c5.4 2.3 7.8 8.6 5.5 14-1.1 2.5-3 4.4-5.5 5.5-.6.3-.9 1-.6 1.5.2.4.6.7 1.1.7.2 0 .3 0 .5-.1 6.6-2.9 9.6-10.5 6.7-17.1-1.3-2.9-3.7-5.3-6.7-6.6z" />
    </svg>
  )
}

export default SvgAnchorFm
