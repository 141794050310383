import React, { useEffect } from "react"
import { Helmet } from "react-helmet"
import { useStaticQuery, graphql } from "gatsby"
import Header from "./header/Header"
import Footer from "./footer/Footer"
import Favicons from "./meta/Favicons"
import AppleWebAppMeta from "./meta/AppleWebAppMeta"
import Facebook from "./meta/Facebook"
import Twitter from "./meta/Twitter"
import "../modules/klaroCss.css"
import { config } from "../modules/klaroConfig"
let Klaro
if (typeof window !== `undefined`) {
  Klaro = require("klaro/dist/klaro-no-css")
}

const Default = ({
  children,
  location,
  canonical,
  title = "Die Summe aller Dinge",
  desc,
  imageFacebook,
  imageFacebookWidth,
  imageFacebookHeight,
  imageTwitter,
  disableHeader,
  disableFooter,
}) => {
  useEffect(() => {
    if (
      typeof window !== `undefined` &&
      process.env.NODE_ENV !== "development"
    ) {
      window.klaro = Klaro
      window.klaroConfig = config
      Klaro.setup(config)
    }
  })

  const data = useStaticQuery(
    graphql`
      query {
        site {
          siteMetadata {
            title
            description
            siteUrl
            author {
              name
              summary
            }
          }
        }

        cockpitSitemeta {
          siteUrl {
            value
          }
          title {
            value
          }
          description {
            value
          }
        }
      }
    `
  )

  return (
    <>
      {/* Main */}
      <Helmet>
        <html lang="de" dir="ltr" />
        <title>{title}</title>
        <meta
          name="description"
          content={desc ? desc : data.cockpitSitemeta.title.value}
        />
        <meta name="robots" content="index,follow" />
        <link
          rel="canonical"
          href={`${
            canonical
              ? canonical
              : data.cockpitSitemeta.siteUrl.value + location.pathname
          }`}
        />
        <meta
          name="author"
          content={`${data.site.siteMetadata.author.name} - ${data.site.siteMetadata.author.summary}`}
        />
      </Helmet>

      {/* Favicon */}
      <Favicons />

      {/* AppleWebAppMeta */}
      <AppleWebAppMeta />

      {/* Facebook */}
      <Facebook
        url={data.cockpitSitemeta.siteUrl.value + location.pathname}
        title={title}
        desc={desc ? desc : data.cockpitSitemeta.title.value}
        siteName={data.cockpitSitemeta.title.value}
        locale="de_DE"
        imageFacebook={imageFacebook && imageFacebook}
        imageFacebookWidth={imageFacebookWidth && imageFacebookWidth}
        imageFacebookHeight={imageFacebookHeight && imageFacebookHeight}
      />

      {/* Twitter */}
      <Twitter
        url={data.cockpitSitemeta.siteUrl.value + location.pathname}
        title={title}
        imageTwitter={imageTwitter && imageTwitter}
        desc={desc ? desc : data.cockpitSitemeta.title.value}
      />

      {/* Scripts */}
      <Helmet>
        {/* Google Analytics */}
        <script
          type="text/plain"
          data-type="application/javascript"
          data-name="googleAnalytics"
          async
          src="https://www.googletagmanager.com/gtag/js?id=G-NQXRCWEYXD"
        ></script>
        <script
          type="text/plain"
          data-type="application/javascript"
          data-name="googleAnalytics"
          async
        >
          {`
          window.dataLayer = window.dataLayer || [];
          function gtag(){dataLayer.push(arguments);}
          gtag('js', new Date());

          gtag('config', 'G-NQXRCWEYXD'), { 'anonymize_ip': true };
          `}
        </script>

        {/* Hotjar */}
        <script
          type="text/plain"
          data-type="application/javascript"
          data-name="hotJar"
          async
        >
          {` (function(h,o,t,j,a,r){
              h.hj=h.hj||function(){(h.hj.q=h.hj.q||[]).push(arguments)};
              h._hjSettings={hjid:2151389,hjsv:6};
              a=o.getElementsByTagName('head')[0];
              r=o.createElement('script');r.async=1;
              r.src=t+h._hjSettings.hjid+j+h._hjSettings.hjsv;
              a.appendChild(r);
          })(window,document,'https://static.hotjar.com/c/hotjar-','.js?sv=');`}
        </script>
      </Helmet>
      {disableHeader ? <></> : <Header />}
      {children}
      {disableFooter ? <></> : <Footer />}
    </>
  )
}

export default Default
